import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    url: String,
    selectedClasses: String,
    unselectedClasses: String,
    mode: String,
  };

  connect() {
    if (!this.hasSelectedClassesValue) {
      this.selectedClassesValue =
        "bg-indigo-600 text-white hover:bg-indigo-700";
    }
    if (!this.hasUnselectedClassesValue) {
      this.unselectedClassesValue = "bg-white text-gray-700 hover:bg-gray-50";
    }
  }

  updateSelection(event) {
    this.buttonTargets.forEach((button) => {
      const input = button.querySelector('input[type="radio"]');
      if (input.checked) {
        this.applyClasses(
          button,
          this.selectedClassesValue,
          this.unselectedClassesValue,
        );

        const url = input.dataset.radioGroupUrlValue;
        const headless = this.modeValue === "headless";

        if (url) {
          patch(url, {
            body: {
              task: { status: input.value, headless: headless },
              close_modal: false,
            },
            headers: {
              Accept: "text/vnd.turbo-stream.html, text/html, application/json",
            },
          });
        }
      } else {
        this.applyClasses(
          button,
          this.unselectedClassesValue,
          this.selectedClassesValue,
        );
      }
    });
  }

  applyClasses(element, classesToAdd, classesToRemove) {
    element.classList.remove(...classesToRemove.split(" "));
    element.classList.add(...classesToAdd.split(" "));
  }
}
