import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["list", "newTaskForm", "newTaskInput"];
  static values = {
    url: String,
    date: String,
    newTaskUrl: String,
    mode: String,
  };

  newTaskInputTargetConnected() {
    if (this.element.dataset.taskListFocusNewTaskInputValue === "true") {
      this.newTaskInputTarget.focus();
    }
  }

  connect() {
    this.sortable = new Sortable(this.listTarget, {
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",
      group: "shared",
      filter: ".new-task-input, .editing, .non-sortable",
      preventOnFilter: false,
      onMove: (evt) => {
        return (
          !evt.related.classList.contains("editing") &&
          !evt.related.classList.contains("new-task-input") &&
          !evt.related.classList.contains("non-sortable")
        );
      },
      onAdd: this.handleAdd.bind(this),
      onEnd: this.updateOrder.bind(this),
      ghostClass: "sortable-ghost",
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag",
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  handleAdd(event) {
    const newTaskInput = event.to.querySelector(".new-task-input");
    if (newTaskInput && event.to.children.length === 2) {
      event.to.insertBefore(event.item, newTaskInput);
    }
  }

  updateOrder(event) {
    const taskId = event.item.dataset.taskId;
    const newPosition = event.newIndex + 1;

    const destinationTaskSetId = event.to.dataset.taskListDateValue;
    const updateUrl = `/today/${destinationTaskSetId}/tasks/${taskId}`;
    const headless = this.modeValue === "headless";

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        task: {
          id: taskId,
          position: newPosition,
          date: destinationTaskSetId,
          headless: headless,
        },
      }),
    })
      .then((response) => response.text())
      .then((html) => {
        console.log(html);
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  focusNewTaskInput(event) {
    // Prevent focusing when clicking on existing tasks or the input itself
    if (
      !event.target.closest("[data-task-id]") &&
      event.target !== this.newTaskInputTarget
    ) {
      this.newTaskInputTarget.focus();
    }
  }

  addTask(event) {
    event.preventDefault();
    const description = this.newTaskInputTarget.value.trim();
    const headless = this.modeValue === "headless";

    if (description) {
      fetch(this.newTaskUrlValue, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          task: { description, date: this.dateValue, headless: headless },
        }),
      })
        .then((response) => response.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);
        })
        .catch((error) => console.error("Error updating task:", error));
    }
  }
}
