import Flatpickr from "stimulus-flatpickr";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect";

import "flatpickr/dist/themes/dark.css";

export default class extends Flatpickr {
  connect() {
    this.config = {
      weekNumbers: true,
      dateFormat: "Y-m-d",
      plugins: [new weekSelect({})],
      locale: {
        firstDayOfWeek: 1,
      },
    };

    super.connect();
  }

  change(selectedDates, dateStr, instance) {
    Turbo.visit(`/day/${dateStr}`);
  }
}
