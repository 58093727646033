import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["backdrop", "dialog", "dismiss"];

  connect() {
    console.log("Modal controller connected");
    this.backdropEnteringClass =
      this.backdropTarget.dataset.enteringClasses || "";
    this.backdropLeavingClass =
      this.backdropTarget.dataset.leavingClasses || "";

    this.dialogEnteringClass = this.dialogTarget.dataset.enteringClasses || "";
    this.dialogLeavingClass = this.dialogTarget.dataset.leavingClasses || "";

    // Use an animation frame to remove the backdrop leaving classes that might be there
    // and add the backdrop entering classes.
    requestAnimationFrame(() => {
      this.backdropTarget.classList.remove(
        ...this.backdropLeavingClass.split(" "),
      );
      this.backdropTarget.classList.add(
        ...this.backdropEnteringClass.split(" "),
      );
    });

    // Use an animation frame to remove any dialog leaving classes that might be there
    // and add the dialog entering classes.
    requestAnimationFrame(() => {
      this.dialogTarget.classList.remove(...this.dialogLeavingClass.split(" "));
      this.dialogTarget.classList.add(...this.dialogEnteringClass.split(" "));
    });
  }

  close(e) {
    // We must stop the link from doing it's think and changing the modal's turbo frame source
    e.preventDefault();
    const abortListener = new AbortController();

    requestAnimationFrame(() => {
      this.backdropTarget.classList.remove(
        ...this.backdropEnteringClass.split(" "),
      );
      this.backdropTarget.classList.add(
        ...this.backdropLeavingClass.split(" "),
      );
    });

    this.dialogTarget.addEventListener(
      "transitionend",
      () => {
        // Remove this event listener because otherwise multiple transitions will
        // cause this function to be called N times, and will generate a console
        // error about the user aborting a request due to the modelEl.src being
        // set multiple times. Listening for one `transitionend` is fine, because
        // they all have a single duration anyway.
        abortListener.abort();

        // Manually we take the close link's href and set the modal turbo frame's
        // src to that path (in this case the /close_modal path), which will clear
        // the modal from the screen, magically, thanks to Turbo Frames.
        const href = this.dismissTarget.href;
        const modalEl = document.querySelector("#modal");
        modalEl.src = href;
      },
      { signal: abortListener.signal },
    );

    requestAnimationFrame(() => {
      this.dialogTarget.classList.remove(
        ...this.dialogEnteringClass.split(" "),
      );
      this.dialogTarget.classList.add(...this.dialogLeavingClass.split(" "));
    });
  }
}
