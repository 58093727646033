import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "originalText",
    "confirmText",
    "confirmedText",
    "progressBar",
    "progressBarContainer",
  ];
  static values = {
    message: String,
    confirmedMessage: String,
    url: String,
    method: String,
  };

  connect() {
    this.confirmed = false;
    this.timeout = null;
  }

  clicked(event) {
    event.preventDefault();

    if (this.confirmed) {
      this.submitForm();
    } else {
      this.showConfirmation();
    }
  }

  showConfirmation() {
    this.confirmed = true;

    // Fade out original text and fade in confirm text
    this.originalTextTarget.classList.add("opacity-0");
    this.confirmTextTarget.classList.remove("opacity-0");

    // Show progress bar container
    this.progressBarContainerTarget.classList.remove("hidden");

    // Initialize progress bar
    this.progressBarTarget.style.transform = "scaleX(1)";

    // Force a reflow
    void this.progressBarTarget.offsetWidth;

    // Start shrinking animation
    this.progressBarTarget.style.transform = "scaleX(0)";

    // Reset after 5 seconds if not clicked
    this.timeout = setTimeout(() => {
      this.reset();
    }, 5000);
  }

  reset() {
    this.confirmed = false;

    // Fade in original text and fade out confirm/confirmed texts
    this.originalTextTarget.classList.remove("opacity-0");
    this.confirmTextTarget.classList.add("opacity-0");
    this.confirmedTextTarget.classList.add("opacity-0");

    // Hide progress bar container
    this.progressBarContainerTarget.classList.add("hidden");

    // Reset progress bar
    this.progressBarTarget.style.transition = "none";
    this.progressBarTarget.style.transform = "scaleX(1)";

    // Re-enable transitions after a brief delay
    setTimeout(() => {
      this.progressBarTarget.style.transition = "transform 5000ms linear";
    }, 0);

    if (this.timeout) clearTimeout(this.timeout);
  }

  async submitForm() {
    const csrfToken = document.querySelector(
      "meta[name='csrf-token']",
    )?.content;

    try {
      const response = await fetch(this.urlValue, {
        method: this.methodValue.toUpperCase(),
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "text/vnd.turbo-stream.html",
        },
        credentials: "same-origin",
      });

      if (response.ok) {
        // Show confirmed state
        this.confirmTextTarget.classList.add("opacity-0");
        this.confirmedTextTarget.classList.remove("opacity-0");

        // Hide progress bar
        this.progressBarContainerTarget.classList.add("hidden");

        if (
          response.headers
            .get("Content-Type")
            ?.includes("text/vnd.turbo-stream.html")
        ) {
          const html = await response.text();
          Turbo.renderStreamMessage(html);
        } else {
          setTimeout(() => {
            Turbo.visit(window.location.href, { action: "replace" });
          }, 1000); // Give time to see the confirmed message
        }
      }
    } catch (error) {
      console.error("Error:", error);
      this.reset();
    }
  }
}
