import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "touchstart",
      this.handleTouchStart.bind(this),
    );
    this.element.addEventListener("touchend", this.handleTouchEnd.bind(this));
    this.element.addEventListener(
      "touchcancel",
      this.handleTouchEnd.bind(this),
    );
  }

  handleTouchStart(event) {
    this.touchTimeout = setTimeout(() => {
      this.revealMenu();
    }, 1000); // 2 seconds
  }

  handleTouchEnd(event) {
    clearTimeout(this.touchTimeout);
  }

  revealMenu() {
    const el = this.element.querySelector(
      "turbo-frame[id^='edit_'] #mobile-entry-action-menu",
    );
    const popoverController =
      window.Stimulus.getControllerForElementAndIdentifier(el, "popover");
    popoverController.open();
  }
}
