import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  #isFocusing;

  connect() {
    this.#isFocusing = false;
  }

  disconnect() {
    this.#isFocusing = false;
  }

  #onFocus() {
    if (this.#isFocusing) {
      return;
    }

    this.#isFocusing = true;
    console.log(`[NewDayController]`, "Owl is visible...");
    const todayEl = document.querySelector("[data-today]");

    if (!todayEl) {
      return;
    }

    const todayElDate = todayEl.dataset.date;

    const now = new Date();
    const currentDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000,
    )
      .toISOString()
      .split("T")[0];

    if (currentDate !== todayElDate) {
      console.log(
        `[NewDayController]`,
        "Refreshing page since today has become tomorrow...",
      );

      window.Turbo.visit("/today", { action: "replace" });
      if (this.#journalEntriesCount() === 0) {
        this.#refreshJournalEntries();
      }
    } else {
      if (this.#journalEntriesEl()) {
        let retries = 0;
        const maxRetries = 3;

        while (retries < maxRetries) {
          try {
            this.#refreshJournalEntries();

            if (this.#journalEntriesCount() === 0) {
              throw new Error("No journal entries found");
            }

            break; // If successful, exit the loop
          } catch (error) {
            console.error(
              `Error reloading journal entries (attempt ${retries + 1}):`,
              error,
            );
            retries++;
          }
        }
      }
    }

    this.#isFocusing = false;
  }

  onVisibilityChange() {
    if (document.visibilityState === "visible") {
      this.#onFocus();
    }
  }

  #journalEntriesEl() {
    return document.querySelector(".entries-view");
  }

  #journalEntriesCount() {
    if (this.#journalEntriesEl()) {
      return document.querySelector(".entries-view ul").children.length;
    }

    return 0;
  }

  #refreshJournalEntries() {
    const journalEntries = this.#journalEntriesEl();

    if (journalEntries) {
      const refreshSrc = journalEntries.dataset.refreshSrc;
      journalEntries.src = "";
      journalEntries.src = refreshSrc;
    }
  }
}
