import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  #timeoutHandle = null;

  static values = {
    url: String,
  };

  geoSettings = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  initialize() {
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.handleSlashCommand = this.handleSlashCommand.bind(this);
  }

  connect() {
    if (this.pageIsTurboPreview()) {
      return;
    }

    console.log("[LocationTracking]", "Controller connected");

    if (!this.urlValue) {
      console.error(
        "[LocationTracking]",
        "Location tracking endpoint URL not defined!",
      );
      return;
    }

    window.addEventListener("visibilitychange", this.onVisibilityChange);
    window.addEventListener("slash-command", this.handleSlashCommand);

    if (!this.#timeoutHandle) {
      this.#timeoutHandle = setTimeout(
        () => {
          this.getCurrentPosition();
        },
        1000 * 60 * 5,
      );
    }

    navigator.permissions.query({ name: "geolocation" }).then(
      function (result) {
        if (result.state == "granted") {
          console.log(
            "[LocationTracking]",
            "Geolocation API permission granted.",
          );
          this.getCurrentPosition();
        } else if (result.state == "prompt") {
          this.getCurrentPosition();
        } else if (result.state == "denied") {
          console.log(
            "[LocationTracking]",
            "Geolocation API permission denied.",
          );
        }
      }.bind(this),
    );
  }

  disconnect() {
    window.removeEventListener("visibilitychange", this.onVisibilityChange);
    window.removeEventListener("slash-command", this.handleSlashCommand);

    if (this.#timeoutHandle) {
      clearTimeout(this.#timeoutHandle);
      this.#timeoutHandle = null;
    }

    console.log("[LocationTracking]", "Controller disconnected");
  }

  pageIsTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview");
  }

  onVisibilityChange() {
    if (!document.hidden && !this.pageIsTurboPreview()) {
      this.getCurrentPosition();
    }
  }

  getCurrentPosition() {
    if (document.documentElement.dataset.tabHasFocus === "false") {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.updatePosition(position, false);
      },
      () => {
        console.info(
          "[LocationTracking] Owl is not the current tab, so can't access to the browser's location. It won't be able to record your locations.",
        );
      },
      this.geoSettings,
    );
  }

  handleSlashCommand(event) {
    if (event.detail.command !== "locate") return;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.updatePosition(position, true, true);
      },
      () => {},
      this.geoSettings,
    );
  }

  updatePosition(position, force = false, placeholder = false) {
    if (this.pageIsTurboPreview() || !window.location.href.endsWith("/today")) {
      return;
    }

    console.log("[LocationTracking]", position);
    this.postLocation(position.coords, force, placeholder);
  }

  postLocation(coords, force = false, placeholder = false) {
    post(this.urlValue, {
      body: {
        journalling_location: {
          lat: coords.latitude,
          long: coords.longitude,
          force: force,
          placeholder: placeholder,
        },
      },
    });
  }
}
