import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  update(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const updateUrl = form.dataset.taskListUrlValue;

    fetch(updateUrl, {
      method: "PATCH",
      body: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.element.classList.remove("editing");
        this.element.classList.add("cursor-move");
        this.isEditing = false;
        // Remove mousedown event listener from the input
        this.inputTarget.removeEventListener(
          "mousedown",
          this.handleInputMousedown.bind(this),
        );
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  complete(event) {
    event.preventDefault();
    const updateUrl = event.currentTarget.dataset.taskCompleteUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  uncomplete(event) {
    event.preventDefault();
    const updateUrl = event.currentTarget.dataset.taskCompleteUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  start(event) {
    event.preventDefault();
    console.log("start");
    const updateUrl = event.currentTarget.dataset.taskStartUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  park(event) {
    event.preventDefault();
    console.log("park");
    const updateUrl = event.currentTarget.dataset.taskParkUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }
}
