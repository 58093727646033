import { get } from "@rails/request.js";

export default class ProtectedNoteHandler {
  constructor() {
    this.handleSlashCommand = this.handleSlashCommand.bind(this);
    window.addEventListener("slash-command", this.handleSlashCommand);
  }

  handleSlashCommand(event) {
    if (event.detail.command !== "protected-note") return;

    const bucketId = event.detail.bucket_id;
    const path = `/buckets/${bucketId}/protected_note/new`;

    get(path);
  }
}
