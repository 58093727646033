import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    k: Number, // Number of petals if k is odd, or twice that if k is even. Can be customized through data attributes.
    scale: Number, // Scale of the rose. Can be customized through data attributes.
    n: Number, // Number of points (increase for more detail). Can be customized through data attributes.
    rotateSpeed: Number, // Speed of rotation. Can be customized through data attributes.
  };

  connect() {
    this.kValue = this.kValue || 8;
    this.scaleValue = this.scaleValue || 400;
    this.nValue = this.nValue || 360;
    this.rotateSpeedValue = this.rotateSpeedValue || 0.0002; // Default rotate speed
    this.createFullScreenCanvas();
    this.angleOffset = 0; // Initial offset angle for rotation
    this.currentAngle = 0;
    this.lastFrameTime = 0;
    requestAnimationFrame(this.animateAndRotateRoseCurve.bind(this));
  }

  createFullScreenCanvas() {
    this.canvas = document.createElement("canvas");
    this.ctx = this.canvas.getContext("2d");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    document.body.appendChild(this.canvas);
  }

  animateAndRotateRoseCurve(timestamp) {
    // Calculate time difference for a smooth animation regardless of frame rate
    const timeDiff = timestamp - this.lastFrameTime;
    this.lastFrameTime = timestamp;

    // Increase the angle offset for rotation
    this.angleOffset += this.rotateSpeedValue * timeDiff;

    if (this.currentAngle < 361) {
      this.drawRotatingRoseCurve(this.currentAngle);
      this.currentAngle++;
    } else {
      // Continue rotating after the curve is fully drawn
      this.currentAngle = 361; // Keep the angle static to keep the curve fully drawn
      this.drawRotatingRoseCurve(this.currentAngle); // Redraw with updated offset
    }
    requestAnimationFrame(this.animateAndRotateRoseCurve.bind(this));
  }

  drawRotatingRoseCurve(upToAngle) {
    const xOffset = this.canvas.width / 2;
    const yOffset = this.canvas.height / 2;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height); // Clear previous frame

    this.ctx.beginPath();
    for (let i = 0; i <= upToAngle; i++) {
      let r = Math.cos(this.kValue * this.toRadians(i)) * this.scaleValue;
      let theta = this.toRadians(i) + this.angleOffset; // Apply rotation
      let x = r * Math.cos(theta) + xOffset;
      let y = r * Math.sin(theta) + yOffset;

      if (i === 0) {
        this.ctx.moveTo(x, y);
      } else {
        this.ctx.lineTo(x, y);
      }
    }
    this.ctx.strokeStyle = "#FF0000"; // Rose curve color
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
    this.ctx.closePath();
  }

  toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  disconnect() {
    this.canvas.remove();
  }
}
