import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.disconnected = false;
  }

  connect() {
    console.log(
      "[LocatingController]",
      "Controller connected. Waiting to refresh.",
    );

    this.timeout = setTimeout(() => {
      if (!this.disconnected) {
        console.log("[LocatingController]", "Refreshing page after 5 seconds.");
        const el = document.querySelector(".entries-view");

        if (el) {
          const newSrc = el.dataset.refreshSrc;
          el.src = newSrc;
        }

        const lvEl = document.querySelector(".location-view");
        if (lvEl) {
          const newSrc = lvEl.dataset.refreshSrc;
          lvEl.src = newSrc;
        }

        this.element.parentElement.remove();
      }
    }, 5000);
  }

  disconnect() {
    console.log(
      "[LocatingController]",
      "Controller disconnected. Clearing timeout.",
    );
    clearTimeout(this.timeout);
    this.disconnected = true;
  }
}
