import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--switch"
export default class extends Controller {
  static targets = ["checkbox", "button", "indicator"];

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;

    const isChecked = this.checkboxTarget.checked;
    this.buttonTarget.setAttribute("aria-checked", isChecked.toString());

    const currentState = isChecked ? "checked" : "unchecked";
    this.buttonTarget.setAttribute("data-state", currentState);
    this.indicatorTarget.setAttribute("data-state", currentState);
  }
}
