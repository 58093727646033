import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log(`[BrowserTabFocus]`, "Connected...");

    document.addEventListener("visibilitychange", this.onChange);
  }

  disconnct() {
    document.removeEventListener("visibilitychange", this.onChange);
  }

  onChange() {
    if (document.hidden) {
      document.documentElement.setAttribute("data-tab-has-focus", "false");
    } else {
      document.documentElement.setAttribute("data-tab-has-focus", "true");
    }
  }
}
