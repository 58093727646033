import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  geoSettings = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  connect() {
    console.log("[RequestLocationPermission]", "Controller connected");
  }

  requestLocationPermission() {
    navigator.permissions.query({ name: "geolocation" }).then(
      function (result) {
        if (result.state == "granted") {
          console.log(
            "[RequestLocationPermission]",
            "Geolocation API permission granted.",
          );

          document
            .getElementById("request_location_tracking_btn")
            .classList.add("hidden");
          document
            .getElementById("location_tracking_allowed")
            .classList.remove("hidden");
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(
            () => {
              document
                .getElementById("request_location_tracking_btn")
                .classList.add("hidden");
              document
                .getElementById("location_tracking_allowed")
                .classList.remove("hidden");
            },
            () => {},
            this.geoSettings,
          );
        } else if (result.state == "denied") {
          console.log(
            "[RequestLocationPermission]",
            "Geolocation API permission denied.",
          );
        }
      }.bind(this),
    );
  }

  requestPushNotificationsPermission() {
    Notification.requestPermission().then((permission) => {
      console.log(
        "[RequestLocationPermission]",
        "Push notifications permission: ",
        permission,
      );

      if (permission === "granted") {
        document
          .getElementById("request_push_notifications_btn")
          .classList.add("hidden");
        document
          .getElementById("push_notifications_allowed")
          .classList.remove("hidden");
      }
    });
  }
}
