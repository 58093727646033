import { Controller } from "@hotwired/stimulus";
import PullToRefresh from "pulltorefreshjs";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.initializePullToRefresh();
  }

  disconnect() {
    this.ptr.destroy();
  }

  initializePullToRefresh() {
    this.ptr = PullToRefresh.init({
      mainElement: ".journal-body",
      triggerElement: this.containerTarget,
      onRefresh: () => {
        const refreshSrc = this.element.dataset.refreshSrc;
        this.element.src = refreshSrc;
      },
      shouldPullToRefresh: () => {
        return !this.containerTarget.scrollTop;
      },
    });
  }
}
