import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  updateStatus(event) {
    const taskId = event.target.dataset.taskId;
    const status = event.target.dataset.status;

    fetch(`/tasks/${taskId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ task: { status: status } }),
    });
  }

  delete(event) {
    event.preventDefault();
    const taskId = event.target.dataset.taskId;

    if (confirm("Are you sure you want to delete this task?")) {
      fetch(`/tasks/${taskId}`, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
          Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error("Network response was not ok.");
        })
        .then((html) => {
          Turbo.renderStreamMessage(html);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }
}
