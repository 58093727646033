import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["list", "newTaskForm", "newTaskInput"];
  static values = {
    url: String,
    date: String,
    newTaskUrl: String,
    mode: String,
  };

  newTaskInputTargetConnected() {
    if (this.element.dataset.focusNewTaskInputValue === "true") {
      this.newTaskInputTarget.focus();
    }
  }

  handleAdd(event) {
    const newTaskInput = event.to.querySelector(".new-task-input");
    if (newTaskInput && event.to.children.length === 2) {
      event.to.insertBefore(event.item, newTaskInput);
    }
  }

  focusNewTaskInput(event) {
    // Prevent focusing when clicking on existing tasks or the input itself
    if (
      !event.target.closest("[data-task-id]") &&
      event.target !== this.newTaskInputTarget
    ) {
      this.newTaskInputTarget.focus();
    }
  }

  addTask(event) {
    event.preventDefault();
    const description = this.newTaskInputTarget.value.trim();
    const headless = this.modeValue === "headless";

    if (description) {
      fetch(this.newTaskUrlValue, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          task: { description, date: this.dateValue, headless: headless },
        }),
      })
        .then((response) => response.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);
        })
        .catch((error) => console.error("Error updating task:", error));
    }
  }
}
