import { Controller } from "@hotwired/stimulus";
import MenuItemHandler from "./slash_menu/menu_item_handler";
import QueryHandler from "./slash_menu/query_handler";
import ProtectedNoteHandler from "./slash_menu/handlers/protected_note_handler";

export default class extends Controller {
  static targets = ["menu", "item", "textarea"];

  initialize() {
    this.menuItemHandler = new MenuItemHandler(this.itemTargets);
    this.queryHandler = new QueryHandler(this.element);
    this.handlers = [new ProtectedNoteHandler()];
    this.showingMenu = false;
    this.textArea = this.element.querySelector("textarea");
  }

  connect() {
    this.allItems = this.itemTargets.map((item) => ({
      content: item.textContent.trim(),
      element: item,
    }));
    this.hideMenu();
  }

  disconnect() {
    this.hideMenu();
  }

  toggleMenu(shouldShow) {
    shouldShow ? this.showMenu() : this.hideMenu();
  }

  filterItemsAndShowMenu() {
    const query = this.textArea.value;
    const currentQuery = this.queryHandler.getCurrentQuery(query);

    if (!this.queryHandler.hasValidQuery(query)) {
      this.hideMenu();
      return;
    }

    this.menuItemHandler.updateItemVisibility(currentQuery);
    this.updateMenuVisibility();
  }

  updateMenuVisibility() {
    const hasVisibleItems = this.menuItemHandler.getVisibleItems().length > 0;
    this.toggleMenu(hasVisibleItems);
    this.menuItemHandler.highlightFirstVisibleItem();
  }

  showMenu() {
    this.menuTarget.classList.remove("hidden");
    this.menuTarget.style.cssText = `position: absolute; left: 0px; bottom: 70px;`;
    this.showingMenu = true;
  }

  hideMenu() {
    this.menuTarget.classList.add("hidden");
    this.menuItemHandler.reset();
    this.showingMenu = false;
  }

  detectSlash(event) {
    if (event.key === "/" && this.textArea.value === "/") {
      this.showMenu();
    }
  }

  navigateMenu(event) {
    if (!this.showingMenu) return;

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
      this.menuItemHandler.adjustCurrentIndex(event.key);
      this.menuItemHandler.highlightCurrentItem(
        this.menuItemHandler.getVisibleItems(),
      );
    }
  }

  clickMenuItem(event) {
    const selectMenuItem = event.currentTarget.closest(
      "[data-slash-menu-target]",
    ).dataset.slashCommand;

    const broadcastEvent = new CustomEvent("slash-command", {
      detail: {
        command: selectMenuItem,
        bucket_id: this.element.dataset.slashMenuBucketIdValue,
      },
    });
    window.dispatchEvent(broadcastEvent);

    this.hideMenu();
  }

  selectMenuItem(event) {
    const query = this.textArea.value;
    if (!this.queryHandler.hasValidQuery(query)) {
      this.hideMenu();
      return;
    }

    event.preventDefault();
    this.textArea.focus();
    this.textArea.value = "";

    const broadcastEvent = new CustomEvent("slash-command", {
      detail: {
        command: this.menuItemHandler.getSelectedMenuItem(),
        bucket_id: this.element.dataset.slashMenuBucketIdValue,
      },
    });
    window.dispatchEvent(broadcastEvent);

    this.hideMenu();
  }
}
