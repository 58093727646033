import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "input"];

  connect() {}

  setSelection(e) {
    e.preventDefault();
    const labelText = e.currentTarget.innerText;
    const id = e.currentTarget.id;

    this.labelTarget.querySelector(".label-text").innerText = labelText;
    this.inputTarget.value = id;
  }
}
