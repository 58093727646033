import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    announcementId: String,
    url: String,
  };

  connect() {
    console.log("AnnouncementBarController connected");
  }

  close() {
    // Start the sliding animation
    this.element.style.transform = "translateY(-100%)";
    this.element.style.marginTop = "-" + this.element.offsetHeight + "px";

    // Wait for the animation to finish before removing the element
    setTimeout(() => {
      this.acknowledgeAnnouncement();
    }, 300); // This should match the duration in the CSS transition
  }

  acknowledgeAnnouncement() {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ announcement_id: this.announcementIdValue }),
    })
      .then((response) => {
        if (response.ok) {
          this.element.remove();
        } else {
          console.error("Failed to acknowledge announcement");
          // Revert the animation if the acknowledgment fails
          this.element.style.transform = "translateY(0)";
          this.element.style.marginTop = "0";
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Revert the animation if there's an error
        this.element.style.transform = "translateY(0)";
        this.element.style.marginTop = "0";
      });
  }
}
