import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sun", "sunPath", "time", "horizon", "horizonMarker"];
  static values = {
    sunrise: String,
    sunset: String,
    solarNoon: String,
    solarMidnight: String,
    daylightHours: Number,
  };

  connect() {
    this.updateSunPosition();
    this.updateHorizonPosition();
    this.intervalId = setInterval(() => this.updateSunPosition(), 60000);
  }

  disconnect() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateSunPosition() {
    const now = new Date();
    const sunrise = this.getTimeToday(this.sunriseValue);
    const sunset = this.getTimeToday(this.sunsetValue);
    const solarMidnight = this.getTimeToday(this.solarMidnightValue);

    // Update time display
    this.timeTarget.textContent = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Calculate sun position
    let progress;
    const fullDay = 24 * 60 * 60 * 1000;
    const dayDuration = sunset - sunrise;
    const nightDuration = fullDay - dayDuration;

    if (now >= sunrise && now <= sunset) {
      // Daytime
      progress = 0.25 + ((now - sunrise) / dayDuration) * 0.5;
    } else if (now > sunset) {
      // After sunset
      progress = 0.75 + ((now - sunset) / nightDuration) * 0.25;
    } else {
      // Before sunrise
      progress =
        (((now - solarMidnight + fullDay) % fullDay) / nightDuration) * 0.25;
    }

    // Ensure progress stays within [0, 1] range
    progress = progress % 1;

    const pathLength = this.sunPathTarget.getTotalLength();
    const point = this.sunPathTarget.getPointAtLength(pathLength * progress);

    // Update sun position
    this.sunTarget.setAttribute("cx", point.x);
    this.sunTarget.setAttribute("cy", point.y);

    // Update sun visibility
    const isDaytime = now >= sunrise && now <= sunset;
    this.sunTarget.style.opacity = isDaytime ? 1 : 0.3;
  }

  updateHorizonPosition() {
    const maxDaylightHours = 16.0;
    const minY = 60;
    const maxY = 120;

    const y =
      maxY - (this.daylightHoursValue / maxDaylightHours) * (maxY - minY);
    this.horizonTarget.setAttribute("y1", y);
    this.horizonTarget.setAttribute("y2", y);

    this.horizonMarkerTargets.forEach((marker) => {
      marker.setAttribute("cy", y);
    });

    this.updateSunPath(y);
  }

  updateSunPath(horizonY) {
    const sunriseX = this.timeToX(this.sunriseValue);
    const sunsetX = this.timeToX(this.sunsetValue);
    const solarNoonX = this.timeToX(this.solarNoonValue);
    const solarMidnightX = this.timeToX(this.solarMidnightValue);
    const nextSolarMidnightX = solarMidnightX + 240;
    const nightDepth = 130;
    const peakHeight = 30;
    const edgeDepth = (nightDepth + horizonY) / 2; // New variable for edge depth

    const pathD =
      `M 0 ${edgeDepth} ` +
      `C ${solarMidnightX * 0.3} ${nightDepth}, ${sunriseX * 0.7} ${horizonY}, ${sunriseX} ${horizonY} ` +
      `C ${(sunriseX + solarNoonX) / 2} ${peakHeight}, ${(solarNoonX + sunsetX) / 2} ${peakHeight}, ${sunsetX} ${horizonY} ` +
      `C ${sunsetX + (nextSolarMidnightX - sunsetX) * 0.3} ${nightDepth}, ${nextSolarMidnightX * 0.7} ${edgeDepth}, 240 ${edgeDepth}`;

    this.sunPathTarget.setAttribute("d", pathD);
  }

  timeToX(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return (hours + minutes / 60) * 10;
  }

  getTimeToday(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }
}
