import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tz"];

  initialize() {
    this.tzTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  connect() {
    console.log("connected");
  }
}
