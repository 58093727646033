import { Controller } from "@hotwired/stimulus";
import TinyGesture from "tinygesture";

// Connects to data-controller="zui--swipe"
export default class extends Controller {
  connect() {
    this.dismissed = false;
    this.handleSwipe();
  }

  handleSwipe() {
    this.gesture = new TinyGesture(this.element);
    this.gesture.on("swiperight", () => {
      this.dispatch("dismiss");
      this.dismissed = true;
      this.gesture.destroy();
    });

    this.gesture.on("panmove", () => {
      if (
        this.gesture.swipingDirection === "horizontal" &&
        this.gesture.touchMoveX > 0
      ) {
        // move the element to the right by the amount of the touchmove
        this.element.style.transform = `translateX(${this.gesture.touchMoveX}px)`;
      }
    });

    this.gesture.on("panend", () => {
      if (!this.dismissed) {
        this.element.style.transform = "";
      }
    });
  }
}
