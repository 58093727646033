import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollIntoView();
    this.disconnect();
  }

  scrollIntoView() {
    this.element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}
