export default class QueryHandler {
  constructor(element) {
    this.element = element;
  }

  getCurrentQuery(query) {
    const queryParts = query.split("/");
    return queryParts[queryParts.length - 1].toLowerCase();
  }

  hasValidQuery(query) {
    return query.startsWith("/") && query.split("/").length >= 2;
  }
}
