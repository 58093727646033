import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollable"];

  connect() {
    if (this.pageIsTurboPreview()) {
      return;
    }

    const element = this.scrollableTarget;
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  pageIsTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview");
  }
}
