import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "item"];
  hideTimeout;
  showTimeout;

  showMenu() {
    clearTimeout(this.hideTimeout);
    clearTimeout(this.showTimeout);

    this.menuTarget.classList.remove("opacity-0");
    this.itemTargets.forEach((item) => (item.dataset.visible = "true"));

    // Delay enabling pointer events until after the animation
    this.showTimeout = setTimeout(() => {
      this.menuTarget.classList.remove("pointer-events-none");
    }, 300); // Adjust this value to match your animation duration
  }

  hideMenu() {
    clearTimeout(this.showTimeout);

    this.hideTimeout = setTimeout(() => {
      if (!this.element.matches(":hover")) {
        this.menuTarget.classList.add("opacity-0", "pointer-events-none");
        this.itemTargets.forEach((item) => delete item.dataset.visible);
      }
    }, 300); // Adjust this delay as needed
  }

  handleMouseEnter() {
    clearTimeout(this.hideTimeout);
    this.showMenu();
  }
}
