import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  #localStorageKey = "iosInstallModalShown";

  connect() {
    if (this.isIOS() && !this.isInStandaloneMode()) {
      const localStorageKeyValue = localStorage.getItem(this.#localStorageKey);
      const iosInstallModalShown = localStorageKeyValue
        ? JSON.parse(localStorageKeyValue)
        : false;

      if (!iosInstallModalShown) {
        this.showInstallPrompt();
      }
    }
  }

  isIOS() {
    return (
      (navigator.userAgent.includes("iOS") ||
        navigator.userAgent.includes("iPhone") ||
        navigator.userAgent.includes("iPad") ||
        navigator.userAgent.includes("iPod")) &&
      "ontouchend" in document
    );
  }

  isInStandaloneMode() {
    return "standalone" in window.navigator && window.navigator.standalone;
  }

  showInstallPrompt() {
    const pwaInstallModalEl = document.querySelector(".pwa-install-modal");
    pwaInstallModalEl.classList.remove("hidden");
    requestAnimationFrame(() => {
      pwaInstallModalEl.classList.add("opacity-100");
    });
  }

  dismiss() {
    localStorage.setItem(this.#localStorageKey, "true");
    const pwaInstallModalEl = document.querySelector(".pwa-install-modal");
    pwaInstallModalEl.classList.add("opacity-0");
    pwaInstallModalEl.classList.add("hidden");
  }
}
