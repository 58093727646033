import { Controller } from "@hotwired/stimulus";
import { fill } from "@domchristie/composite";

export default class OptimisticFormController extends Controller {
  static targets = ["actions"];

  async performActions() {
    this.element.insertAdjacentHTML(
      "beforeend",
      fill(this.actionsTarget, {
        controller: this,
        params: this.params,
        currentTime: this.currentTime,
      }),
    );
  }

  get params() {
    return Object.fromEntries(new FormData(this.element));
  }

  get currentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
}
