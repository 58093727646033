import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async updateApp() {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(
        registrations.map((registration) => registration.unregister()),
      );
      location.reload();
    } catch (error) {
      console.error("Error updating app:", error);
    }
  }
}
