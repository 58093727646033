import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dismissable"
export default class extends Controller {
  connect() {
    this.updateTime(); // Initial update when the controller connects
    this.startUpdating(); // Start real-time updating
  }

  disconnect() {
    this.stopUpdating(); // Stop updating when the controller disconnects
  }

  startUpdating() {
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000); // Update every second
  }

  stopUpdating() {
    clearInterval(this.timer);
  }

  updateTime() {
    const currentTime = this.getCurrentTime();
    this.element.textContent = currentTime;
  }

  getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
}
