export default class MenuItemHandler {
  constructor(itemTargets) {
    this.itemTargets = itemTargets;
    this.currentIndex = -1;
    this.menuItemHighlight = "bg-plate-3";
  }

  updateItemVisibility(currentQuery) {
    this.itemTargets.forEach((item) => {
      const isVisible = item
        .querySelector("p")
        .textContent.toLowerCase()
        .startsWith(currentQuery);
      item.style.display = isVisible ? "" : "none";
    });

    if (this.itemTargets.every((item) => item.style.display === "none")) {
      this.currentIndex = -1; // Reset currentIndex if no items are visible
    }
  }

  highlightFirstVisibleItem() {
    const visibleItems = this.getVisibleItems();
    this.currentIndex = 0;
    this.highlightCurrentItem(visibleItems);
  }

  getVisibleItems() {
    return this.itemTargets.filter((item) => item.style.display !== "none");
  }

  highlightCurrentItem(visibleItems) {
    visibleItems.forEach((item, index) => {
      item.classList.toggle(
        this.menuItemHighlight,
        index === this.currentIndex,
      );
    });
  }

  adjustCurrentIndex(key) {
    if (key !== "ArrowDown" && key !== "ArrowUp") return;

    const visibleItems = this.getVisibleItems();
    if (visibleItems.length === 0) return;

    this.currentIndex =
      key === "ArrowDown"
        ? (this.currentIndex + 1) % visibleItems.length
        : (this.currentIndex - 1 + visibleItems.length) % visibleItems.length;
  }

  reset() {
    this.currentIndex = -1;
  }

  // Return the selected menu item
  getSelectedMenuItem() {
    return this.getVisibleItems()[this.currentIndex].dataset.slashCommand;
  }
}
