import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    cssClasses: String,
    distance: Number,
  };

  connect() {
    window.addEventListener("scroll", this.onScroll.bind(this), {
      passive: true,
    });
    this.onScroll();
  }

  disconnect() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    const height = document.documentElement.scrollTop;
    const classes = this.cssClassesValue.split(" ");

    if (height >= this.distanceValue) {
      this.element.classList.add(...classes);
    } else {
      this.element.classList.remove(...classes);
    }
  }
}
