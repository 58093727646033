import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["watched"];
  hasChanged = false;

  initialize() {
    this.warnUser = this.warnUser.bind(this);
    this.handleTurboNavigation = this.handleTurboNavigation.bind(this);
  }

  connect() {
    this.watchedTargets.forEach((target) => {
      target.addEventListener("change", () => (this.hasChanged = true));
      target.addEventListener("input", () => (this.hasChanged = true));
    });
    window.addEventListener("beforeunload", this.warnUser);
    document.addEventListener("turbo:before-visit", this.handleTurboNavigation);
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.warnUser);
    document.removeEventListener(
      "turbo:before-visit",
      this.handleTurboNavigation,
    );
  }

  warnUser(event) {
    if (this.#shouldWarn()) {
      event.preventDefault();
      event.returnValue = ""; // Chrome requires returnValue to be set.
      return "You have unsaved changes! Are you sure you want to leave?";
    }
  }

  handleTurboNavigation(event) {
    if (
      this.#shouldWarn() &&
      !confirm("You have unsaved changes! Are you sure you want to leave?")
    ) {
      event.preventDefault();
    }
  }

  reset() {
    this.hasChanged = false;
  }

  #shouldWarn() {
    return this.hasChanged && this.watchedTarget.value != "";
  }
}
